.create-order-page-container {
    display: block;
}

.create-order-page-card-product-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    gap: 20px;
    max-width: 1000px;
    margin: auto;
    margin-top: 25px;
}

.create-order-page-amount-container {
    display: block;
}

.create-order-page-form-option-container {
    display: block;
    max-width: 1000px;
    margin: auto;
    margin-top: 25px;
}