.header-container {
    display: block;
    position: sticky;
    top: 0;
    z-index: 1;
}

.navbar {
    overflow: hidden;
    background-color: #033375;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.navbar a span {
    float: left;
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
}

.navbar .right {
    padding: 28px;
    margin: 0 0 0 auto;
}

.navbar .right {
    padding: 28px;
    margin: 0 0 0 auto;
    color: white;
}

.navbar .left {
    padding: 28px 28px 28px 5px;
    margin: 0 auto 0 0;
    color: white;
}

.navbar a:hover {
    background-color: #ddd;
    color: black;
}

.navbar .right:hover {
    background-color: #ddd;
    color: black;
}


.header-icon-logo {
    width: 70px;
}

.back-button {
    margin: 0 0 0 20px;
    color: #ffffff;
}