.login-page-container {
    width: 100%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-page-inputs-container {
    display: block;
    margin: auto;
    max-width: 350px;
}

.login-page-input-container {
    display: block;
    width: 100%;
    margin: 0 0 15px 0;
}

.login-page-button-container {
    display: block;
    width: 100%;
}

.login-page-image-logo-container {
    text-align: center;
    display: block;
    margin: auto auto 10px auto;
    max-width: 350px;
}

.login-page-image-logo {
    width: 100px;
}

.login-page-title-container {
    display: block;
    text-align: center;
}