
.card-product-container {
    display: block;
}

.card-product {
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    height: 280px;
    box-shadow: 0 4px 8px 0 rgba(39, 39, 39, 0.2);
}

.card-product-container:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

.card-product-data{
    display: block;
}

.card-product-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-product-image {
    width:100px;
}

.card-product-data-price {
    display: flex;
    align-items: center;
  justify-content: center;
}

.p-no-margin {
    margin: 0px;
}