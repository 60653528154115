
.card-option-container {
    display: block;
}

.card-option {
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
}

.card-option-image {
    width:100px;
}